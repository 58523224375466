import { useEffect } from "react";
import { useStytchMember, useStytchMemberSession } from "@stytch/react/b2b";
import {
  Bell as BellIcon,
  Menu as Bars3Icon,
  Home as HomeIcon,
  Activity as ActivityIcon,
  Wheat as GrainIcon,
  FlaskConical as FlaskConicalIcon,
  LineChart as LineChartIcon,
  X as XMarkIcon,
  Upload as UploadIcon,
  BarChartBig as BarChartBigIcon,
  Zap as ZapIcon,
} from "lucide-react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "./components/ui/toaster";
import AppPanel from "./components/ui/AppPanel";
import { DefaultStytchLogin } from "./section/StytchLogin";
import { TicketDashboard } from "./section/tickets/Dashboard";
import { DormanStagedDashboard } from "./section/dorman/DormanStagedDashboard";
import { DeveloperDashboardPage } from "./section/developer/Dashboard";
import { ClientNotificationsDashboard } from "./section/developer/Notification";
import { DeveloperTestPage } from "./section/developer/TestPage";
import { TicketUpload } from "./section/tickets/TicketUpload";
import { SalesAndPurchaseReports } from "./section/tickets/SalesAndPurchaseReports";
import { CogsReports } from "./section/tickets/CogsReports";
import { SpreadReport } from "./section/reports/SpreadReport";
import { LocationDashboard } from "./section/general/LocationDashboard";
import { ContactsDashboard } from "./section/general/ContactsDashboard";
import { MarketZoneDashboard } from "./section/general/MarketZoneDashboard";
import { DestinationMessagingDashboard } from "./section/destination/DestinationMessagingDashboard";
import { DestinationMessagingConsentScreenshot } from "./section/destination/DestinationMessagingConsentScreenshot";
import { DestinationMessagingConsent } from "./section/destination/DestinationMessagingConsent";
import { GeneralContractsDashboard } from "./section/general/GeneralContractsDashboard";
import { StagedGeneralContractsDashboard } from "./section/general/StagedGeneralContractsDashboard";
import { AgtechContractsDashboard } from "./section/general/AgtechContractsDashboard";
import { ArReportDashboard } from "./section/reports/ArReportDashboard";
import { MarketingTransactionalReport } from "./section/reports/MarketingTransactionalReport";
import { QueuedDocumentsReport } from "./section/document/QueuedDocumentsReport";
import { TicketJobsDashboard } from "./section/tickets/TicketJobsDashboard";
import { TradingTicketDashboard } from "./section/tickets/TradingDashboard";
import { OtcDashboard } from "./section/otc/Dashboard";
import { PositionsDashboard } from "./section/otc/PositionsDashboard";
import { BacktestPage } from "./section/trading/backtest";
import { AtsDashboardPage } from "./section/trading/ats/dashboard";
import { AtsLivePage } from "./section/trading/ats/live";
import { RecommendationAnalysis } from "./section/AnalysisPortal/analysis";
import { RecommendationAlert } from "./section/AnalysisPortal/alert";
import { HedgeBeaconMarketThoughts } from "./section/AnalysisPortal/market_thoughts";
import { RoutePath, pagesList } from "./section/router";
import { ManageCustomersPage } from "./section/customer/ManageCustomers";
import { ManageTradersPage } from "./section/trader/ManageTraders";
import { DeveloperJobsDashboard } from "./section/developer/JobsDashboard";
import { NewsArticlesDashboard } from "./section/news/NewsArticlesDashboard";
import { ManagePermissionsPage } from "./section/permissions/ManagePermissions";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from "./components/ui/navigation-menu";
import { cn } from "./lib/utils";
import { PossiblePackage, doesUserHavePackages } from "./lib/models/auth";

// TODO Log out when api calls returns unauthenticated because of expired tokens
// TODO add toasts and messages for login pages to tell the user why.
// Expired token etc
function ProtectedRoute({
  children,
  requiredPackages,
}: {
  children: JSX.Element;
  requiredPackages?: PossiblePackage[];
}): JSX.Element | null {
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();
  const navigate = useNavigate();

  // NOTE: can check session.authentication_factors.length < 2
  // for checking that 2FA exists
  useEffect(() => {
    if (!session) {
      navigate("/login");
    }

    if (
      session &&
      member &&
      !doesUserHavePackages({ requiredPackages, member })
    ) {
      navigate("/");
    }
  }, [session, member, requiredPackages]);

  if (!session) {
    return null;
  }

  if (!!member && !doesUserHavePackages({ requiredPackages, member })) {
    return <MissingPermissionsPage />;
  }

  return children;
}

function MissingPermissionsPage() {
  return (
    <div>
      <h1>Insufficient priviledges to access this page</h1>
      <p>
        Please contact your administrator if you believe you should have access
        to this page.
      </p>
    </div>
  );
}

function Dashboard() {
  const { member } = useStytchMember();
  const items = pagesList({ member, filterByPackages: true }).filter(
    (item) => !item.excludeFromDashboard && item.href !== RoutePath.Dashboard,
  );

  return (
    <div>
      <div className="container flex flex-col mb-2">
        <h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Dashboard
        </h2>
        <ul
          role="list"
          className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2"
        >
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                <div
                  className={cn(
                    item.background,
                    "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg",
                  )}
                >
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <a href={item.href} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <span>{item.name}</span>
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const pageElements = [
  {
    path: RoutePath.ManagePermissions,
    element: ManagePermissionsPage,
  },
  {
    path: RoutePath.NewsArticles,
    element: NewsArticlesDashboard,
  },
  {
    path: RoutePath.Dashboard,
    element: Dashboard,
  },
  {
    path: RoutePath.ARReport,
    element: ArReportDashboard,
  },
  {
    path: RoutePath.HedgeBeaconAnalysis,
    element: RecommendationAnalysis,
  },
  {
    path: RoutePath.HedgeBeaconAlert,
    element: RecommendationAlert,
  },
  {
    path: RoutePath.HedgeBeaconMarketThoughts,
    element: HedgeBeaconMarketThoughts,
  },
  {
    path: RoutePath.TicketsUpload,
    element: TicketUpload,
  },
  {
    path: RoutePath.SalesAndPurchaseReports,
    element: SalesAndPurchaseReports,
  },
  {
    path: RoutePath.CogsReports,
    element: CogsReports,
  },
  {
    path: RoutePath.SpreadReport,
    element: SpreadReport,
  },
  {
    path: RoutePath.MarketingTransactionalReport,
    element: MarketingTransactionalReport,
  },
  {
    path: RoutePath.QueuedDocuments,
    element: QueuedDocumentsReport,
  },
  {
    path: RoutePath.TicketsWeightDashboard,
    element: TicketDashboard,
  },
  {
    path: RoutePath.TicketsJobsDashboard,
    element: TicketJobsDashboard,
  },
  {
    path: RoutePath.ManageCustomers,
    element: ManageCustomersPage,
  },
  {
    path: RoutePath.ManageTraders,
    element: ManageTradersPage,
  },
  {
    path: RoutePath.TicketsTradingDashboard,
    element: TradingTicketDashboard,
  },
  {
    path: RoutePath.OtcDashboard,
    element: OtcDashboard,
  },
  {
    path: RoutePath.LocationDashboard,
    element: LocationDashboard,
  },
  {
    path: RoutePath.ContactsDashboard,
    element: ContactsDashboard,
  },
  {
    path: RoutePath.MarketZoneDashboard,
    element: MarketZoneDashboard,
  },
  {
    path: RoutePath.DestinationMessagingDashboard,
    element: DestinationMessagingDashboard,
  },
  {
    path: RoutePath.DestinationMessagingConsentScreenshot,
    element: DestinationMessagingConsentScreenshot,
  },
  {
    path: RoutePath.DestinationMessagingConsent,
    element: DestinationMessagingConsent,
  },
  {
    path: RoutePath.GeneralContractsDashboard,
    element: GeneralContractsDashboard,
  },
  {
    path: RoutePath.StagedGeneralContractsDashboard,
    element: StagedGeneralContractsDashboard,
  },
  {
    path: RoutePath.AgtechContractsDashboard,
    element: AgtechContractsDashboard,
  },
  {
    path: RoutePath.PositionsDashboard,
    element: PositionsDashboard,
  },
  {
    path: RoutePath.TradingAtsLive,
    element: AtsLivePage,
  },
  {
    path: RoutePath.TradingBacktest,
    element: BacktestPage,
  },
  {
    path: RoutePath.TradingAtsData,
    element: AtsDashboardPage,
  },
  {
    path: RoutePath.DeveloperDashboard,
    element: DeveloperDashboardPage,
  },
  {
    path: RoutePath.DeveloperTestPage,
    element: DeveloperTestPage,
  },
  {
    path: RoutePath.DeveloperClientNotifications,
    element: ClientNotificationsDashboard,
  },
  {
    path: RoutePath.DormanStagedDashboard,
    element: DormanStagedDashboard,
  },
  {
    path: RoutePath.Login,
    element: DefaultStytchLogin,
  },
  {
    path: RoutePath.Authenticate,
    element: DefaultStytchLogin,
  },
  {
    path: RoutePath.DeveloperJobsDashboard,
    element: DeveloperJobsDashboard,
  },
];

const router = createBrowserRouter(
  pageElements.map((item) => {
    const pageItem = pagesList({ filterByPackages: false }).find(
      (page) => page.href === item.path,
    );
    if (!pageItem) throw new Error("Failed to find item");

    const Element = item.element;

    if (pageItem.isProtected) {
      return {
        path: item.path,
        element: (
          <ProtectedRoute requiredPackages={pageItem.requiredPackages}>
            <AppPanel>
              <Element />
            </AppPanel>
          </ProtectedRoute>
        ),
      };
    }

    return {
      path: item.path,
      element: (
        <UnAuthedPageFrame shouldStayOnUnAuthed={pageItem.shouldStayOnUnAuthed}>
          <Element />
        </UnAuthedPageFrame>
      ),
    };
  }),
);

function App() {
  // TODO add a router and check store for token to private protect routes
  return (
    <main className="flex-1 overflow-y-auto overflow-x-clip">
      <RouterProvider router={router} />
      <Toaster />
    </main>
  );
}

export default App;

// TODO investigate Stytch Refresh Session
// https://stytch.com/docs/b2b/sdks/javascript-sdk/session-management#refresh-session
export function UnAuthedPageFrame({ children, shouldStayOnUnAuthed }: { children: JSX.Element; shouldStayOnUnAuthed?: boolean }) {
  // TODO convert protected and unauthed to handle stytch auth
  const { session } = useStytchMemberSession();
  const navigate = useNavigate();

  // NOTE: can check session.authentication_factors.length < 2
  // for checking that 2FA exists
  useEffect(() => {
    if (!shouldStayOnUnAuthed && session) {
      navigate("/");
    }
  }, [session, shouldStayOnUnAuthed]);

  if (!!session && !shouldStayOnUnAuthed) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch h-screen w-screen overflow-clip">
      <main className="flex-1 overflow-y-auto overflow-x-clip">
        <div className="flex items-center flex-col h-full">
          <div className="container h-full">{children}</div>
        </div>
      </main>
    </div>
  );
}
