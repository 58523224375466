import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  AnalystCalendarSummary,
  AnalystAnalysis,
  AnalystRecommendationType,
  AnalystRecommendation,
} from "../models/analystRecommendation";

const ENTITY_KEY = "analystRecommendations";

export enum ProjectFullName {
  HedgeBeacon = "hedge_beacon",
  MarketThoughts = "hedge_beacon_market_thoughts",
  CodetiTest = "codeti_test",
  LighthouseTest = "lighthouse_test",
}

export function useUpsertRecommendation() {
  const queryClient = useQueryClient();

  return async (data: any) => {
    if (!data?.id) throw new Error("Failed to upsert. Invalid recommendation.");
    const result = await fetchApiPost<AnalystRecommendation>(
      `analyst/recommendation`,
      data,
    );
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY], exact: true });
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY, data.id] });
    return result;
  };
}

export const MarketThoughtsValidation = z.object({
  projectName: z.enum([ProjectFullName.CodetiTest, ProjectFullName.LighthouseTest, ProjectFullName.MarketThoughts]),
  details: z.string().min(1),
  schedule_send: z.object({
    date: z.string().refine((val: string) => /^\d{4}-\d{2}-\d{2}$/.test(val), "Scheduled date must be in YYYY-MM-DD format").optional(),
    time: z.string().refine((val: string) => /^\d{2}:\d{2}:\d{2}$/.test(val), "Time must be in HH:mm:ss format"),
  }).optional(),
});

export function usePublishMarketThoughts() {
  const queryClient = useQueryClient();

  return async (data: typeof MarketThoughtsValidation._type) => {
    const result = await fetchApiPost<any>(
      `analyst/recommendation/publish/market_thoughts`,
      data,
    );
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY] });
    return result;
  };
}

export function usePublishRecommendations() {
  const queryClient = useQueryClient();

  return async (data: {
    published: boolean;
    recommendationIds: string[];
    projectName: ProjectFullName;
  }) => {
    if (!data?.recommendationIds?.length)
      throw new Error("Failed to publish. Invalid analysis.");
    const result = await fetchApiPut<any>(
      `analyst/recommendation/publish`,
      data,
    );
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY], exact: true });
    queryClient.invalidateQueries({
      queryKey: [ENTITY_KEY, ...data.recommendationIds],
    });
    return result;
  };
}

export function useDeleteRecommendation() {
  const queryClient = useQueryClient();

  return async (dataId: string) => {
    if (!dataId) return { success: false, data: {}, message: "Missing" };
    const result = await fetchApiDelete(`analyst/recommendation/${dataId}`);
    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY] });
    return result;
  };
}

export function useAnalystRecommendations({
  from,
  to,
  recommendationType,
  limit,
}: {
  from: Date;
  to: Date;
  recommendationType: AnalystRecommendationType;
  limit?: number;
}) {
  const queryParams = new URLSearchParams();

  if (from) {
    queryParams.append("from", from.toISOString());
  }

  if (to) {
    queryParams.append("to", to.toISOString());
  }

  if (recommendationType) {
    queryParams.append("type", recommendationType);
  }

  if (limit) {
    queryParams.append("limit", limit.toString());
  }

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      ENTITY_KEY,
      "analystRecommendationsList",
      from,
      to,
      recommendationType,
    ],
    queryFn: () =>
      fetchApiGet<AnalystRecommendation[]>(
        "analyst/recommendation",
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}

export function useLastDayAnalysis(date: Date) {
  const queryParams = new URLSearchParams();
  queryParams.append("type", AnalystRecommendationType.Analysis);
  queryParams.append("date", date.toISOString());

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [ENTITY_KEY, "lastDay", date],
    queryFn: () =>
      fetchApiPost<AnalystAnalysis[]>(
        "analyst/recommendation/lastDay",
        {},
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}

export function useAnalystCalendarSummary(clearKey: any) {
  const queryParams = new URLSearchParams();

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [ENTITY_KEY, "summary", clearKey],
    queryFn: () =>
      fetchApiGet<AnalystCalendarSummary>(
        "analyst/recommendation/calendar/summary",
        queryParams,
      ),
    retry: 1,
    cacheTime: 0,
  });
}

export function fetchMarketThoughtsPage(page: number) {
  const queryParams = new URLSearchParams();
  queryParams.append("offset", `${page}`);

    return fetchApiGet<{
        data: AnalystRecommendation[]
        total: number;
        totalPages: number;
        pageSize: number;
        currentPage: number;
    }>(`analyst/recommendation/market_thoughts`, queryParams);
}

export function useListMarketThoughts({ page, queryFn }: {
    page: number,
    queryFn: any,
}) {
  const queryParams = new URLSearchParams();
  queryParams.append("offset", `${page}`);

    return useQuery({
        queryKey: ["analystRecommendations", "marketThoughts", queryParams.toString()],
        queryFn,
        retry: 1,
        cacheTime: 0,
        keepPreviousData: true,
    });
}