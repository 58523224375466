import { create } from "zustand";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, cn } from "../../lib/utils";
import {
    ListFilter as ListFilterIcon,
} from "lucide-react";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import { Customer } from "../../lib/api/reports";
import * as FormHelper from "../../lib/form";
import { CommodityOptions, Commodities } from "../../lib/api/commodity";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { toast, useToast } from "../../components/ui/use-toast";
import {
    useListBaseData,
    useListStagedData,
    useCreateUploadJob,
    FilterOptions,
    StagedRow,
    BaseInfo,
    useUpdateEnabledStatus,
    useUpdateEnabledBulkStatus,
    useSendMessage,
    useListHistoricalData,
    HistoricalData,
    HistoricalMessageReceiver,
    useListInternalUsers,
    InternalUser,
    useCancelFutureMessage,
    useManageException,
    useListFutureData,
    FutureRow,
    useUpdateFutureMessage,
    useGenerateTwilioReport,
} from "../../lib/api/destination";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/ui/tabs";
import { FormGridHelper } from "../reports/helper";
import { SuccessResult, ValidationError } from "../../lib/responseResults";
import { useGetCreateJobStatus } from "../../lib/api/dorman";
import { UploadPresignedFile } from "@/lib/file";
import { Loader } from "@/components/ui/loader";
import { Textarea } from "@/components/ui/textarea";
import { DatePicker } from "@/components/ui/typeable_date_picker";
import { TimePicker } from "@/components/ui/time_picker";
import { Label } from "@/components/ui/label";
import { findByKey } from "@/lib/prelude";
import { MarketZone } from "@/lib/api/generalContract";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { DataTable } from "@/components/ui/dataTable";
import { BaseFormSchema as BaseCustomerSchema } from "@/lib/schemas/customer";
import { BaseFormSchema as BaseMarketZoneSchema } from "@/lib/schemas/marketZone";
import { useCreateCustomer, useListCustomers, useUpdateCustomer } from "@/lib/api/customer";
import { Checkbox } from "@/components/ui/checkbox";
import { CustomerForm,  useBearStore as useCustomerBearStore } from "../customer/ManageCustomers";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const MarketZoneSchema = {
    ...BaseMarketZoneSchema,
    exception_type: { hidden: true, label: "Exception Type", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    exception_id: { hidden: true, label: "Exception ID", input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Required },
};

const useSortFilterState = create((set) => ({
    filters: {
        shipment_end: moment.tz(CommonDestinationTimezone).endOf("month").format("YYYY-MM-DD")
    } as FilterOptions,
    setFilterState: (newState: FilterOptions) =>
        set((state: any) => ({ filters: newState })),
    clearAll: () => set((state: any) => ({ filters: {} })),
}));

const useFutureState = create((set) => ({
    isDeleting: false,
    selectedDestinationId: undefined,
    selectedCommodity: undefined,
    setIsDeleting: (newState: boolean) =>
        set((state: any) => ({ isDeleting: newState })),
    setSelectedDestinationId: (newState: string | undefined) =>
        set((state: any) => ({ selectedDestinationId: newState })),
    setSelectedCommodity: (newState: string[]) =>
        set((state: any) => ({ selectedCommodity: newState })),
}));

const useHistoricalState = create((set) => ({
    selectedDestinationId: undefined,
    selectedCommodity: undefined,
    sent_date: new Date(),
    setSentDate: (newState: string | undefined) =>
        set((state: any) => ({ sent_date: newState })),
    setSelectedDestinationId: (newState: string | undefined) =>
        set((state: any) => ({ selectedDestinationId: newState })),
    setSelectedCommodity: (newState: string[]) =>
        set((state: any) => ({ selectedCommodity: newState })),
    clearFilterKey: (messageId: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: undefined
        } })),
    setFilterState: (messageId: string, newState: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: newState
        } })),
}));

const useMessagesState = create((set) => ({
    isTest: false,
    messages: {} as Record<string, string>,
    selectedDestinationId: undefined,
    selectedCommodities: [],
    scheduledDate: undefined,
    scheduledTime: "08:00:00",
    isCommunicating: false,
    selectedInternalUsers: {} as Record<string, boolean>,
    setIsTest: (newState: boolean) =>
        set((state: any) => ({ isTest: newState })),
    setScheduledDate: (newState: string | undefined) =>
        set((state: any) => ({ scheduledDate: newState })),
    setScheduledTime: (newState: string | undefined) =>
        set((state: any) => ({ scheduledTime: newState })),
    setIsCommunicating: (newState: boolean) =>
        set((state: any) => ({ isCommunicating: newState })),
    setSelectedDestinationId: (newState: string | undefined) =>
        set((state: any) => ({ selectedDestinationId: newState })),
    setSelectedCommodities: (newState: string[]) =>
        set((state: any) => ({ selectedCommodities: newState })),
    clearFilterKey: (messageId: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: undefined
        } })),
    setFilterState: (messageId: string, newState: string) =>
        set((state: any) => ({ messages: {
          ...state.messages,
          [messageId]: newState
        } })),
    clearAll: () => set((state: any) => ({ messages: {} })),
    setSelectedInternalUsers: (users: Record<string, boolean>) =>
        set((state: any) => ({ selectedInternalUsers: users })),
}));

const useUploadState = create((set) => ({
    shipment_start: undefined,
    shipment_end: moment.tz(CommonDestinationTimezone).endOf("month").toDate(),
    setShipmentStart: (newState: string | undefined) =>
        set((state: any) => ({ shipment_start: newState })),
    setShipmentEnd: (newState: string | undefined) =>
        set((state: any) => ({ shipment_end: newState })),
}));

export const HistoricalFilterFormSchema = {
    message_date: { label: "Message Date", input_type: InputType.Date, input_type_validation: InputFormType.DateString, required_status: InputRequiredStatus.Required },

    destination_id: { label: "Destination", options_reference_id: "destinationOptions", input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },
    commodity: { label: "Commodity", options_reference_id: "commodityOptions", input_type: InputType.Enum, input_type_validation: InputFormType.Enum, required_status: InputRequiredStatus.Optional },
}

export const CreateJobFormSchema = {
    contract_release_file: { label: "Contract Release Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
    contract_dp_release_file: { label: "Contract Release DP Excel", fileExtensions: [".xlsx", ".xls"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },

    freight_orders_file: { label: "Freight Orders Csv", fileExtensions: [".csv"], input_type: InputType.File, input_type_validation: InputFormType.File, required_status: InputRequiredStatus.Required },
};

const createJobState = FormHelper.generateBaseState({
    schemas: [CreateJobFormSchema],
    baseSchema: CreateJobFormSchema,
});

const marketZoneCreateState = FormHelper.generateBaseState({
    schemas: [MarketZoneSchema],
    baseSchema: MarketZoneSchema,
});

function MarketZoneCreateDynamicForm({
    onCloseForm,
    marketZones,
    customers,
}: {
    onCloseForm: any;
    marketZones: BaseInfo["market_zones"];
    customers: BaseInfo["customers"];
}) {
    const inputState: any = marketZoneCreateState.useInputsState((state: any) => state);
    const submissionState: any = marketZoneCreateState.useSubmissionState((state: any) => state);
    const manageException = useManageException();
    const { toast } = useToast();

    return FormLayout.RenderFullPrimitiveGridLayout({
        id: "ManageMarketZoneException",
        activeSchema: MarketZoneSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
            customerOptions: genIdOptionList(customers, "long_name"),
        },
        onSubmitTo: (item: any) => manageException(item, { exception_type: "market_zone" }),
        onCloseForm,
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to create Market Zone",
                    description: "",
                });
            } else {
                toast({
                    title: "Created",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    });
}

function CustomerFormModal({ isOpen, setIsOpen }: {
    isOpen: boolean;
    setIsOpen: any;
}) {
    const { toast } = useToast();
    const [isSaving, setIsSaving] = useState(false);
    const { updateCustomerId, exception_id } = useCustomerBearStore((state) => ({ updateCustomerId: state.updateCustomerId, exception_id: state.exception_id }));
    const manageException = useManageException();
    const clearCustomer = useCustomerBearStore((state) => state.clearCustomer);

    const onSubmit = async (formData: any) => {
    const exception_id = formData.exception_id;
    const exception_type = formData.exception_type;
        const data: any = {
            ...formData,
            id: updateCustomerId || exception_id,
            exception_id,
            exception_type,
        };
        const result = await manageException(data, { exception_type });

        if (!result.success) {
            toast({
                title: updateCustomerId ? "Failed to update Customer" : "Failed to create Customer",
                description: "",
            });
        } else {
            toast({
                title: updateCustomerId ? "Updated" : "Created",
                description: "",
            });
            setIsOpen(false);
            clearCustomer();
        }
        return result.success;
    };

    return (
        <Dialog
            onOpenChange={setIsOpen}
            open={isOpen}
            defaultOpen={false}
        >
            <DialogContent className="sm:max-w-lg">
                <DialogHeader>
                    <DialogTitle>{updateCustomerId ? "Update Customer" : "Create Customer"}</DialogTitle>
                </DialogHeader>
                <CustomerForm onSubmit={onSubmit} allowUpdatingClientId={updateCustomerId ? false : true } />
                <DialogFooter className="sm:justify-start"></DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

function MarketZoneCreateFormModal({ isOpen, setIsOpen, customers, marketZones }: {
    isOpen: boolean;
    setIsOpen: any;
    customers: BaseInfo["customers"];
    marketZones: BaseInfo["market_zones"];
}) {
    return (
        <PrimitiveDialogForm
            isOpen={isOpen}
            hasTrigger={false}
            onDialogChange={setIsOpen}
            dialogTitle={"Create Market Zone"} form={<MarketZoneCreateDynamicForm onCloseForm={setIsOpen} marketZones={marketZones} customers={customers} />
        }/>
    );
}

export function DestinationMessagingDashboard() {
    const baseDataRes = useListBaseData();
    const [jobId, setJobId] = useState<string | undefined>(undefined);
    const loadingOrErrorUi = GenerateLoadingOrError([baseDataRes])
    const [tabValue, setTabValue] = useState("upload");

    if (loadingOrErrorUi) {
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Destination Messaging
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const baseData = baseDataRes.data?.data || {};

    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Destination Messaging
        </h1>
        <div className="flex justify-end my-4">
          <Tabs value={tabValue} onValueChange={setTabValue} className="w-full">
            <TabsList>
              <TabsTrigger value="upload">Upload</TabsTrigger>
              <TabsTrigger value="historical">Historical</TabsTrigger>
              <TabsTrigger value="staged">Staged</TabsTrigger>
              <TabsTrigger value="exceptions">Exceptions</TabsTrigger>
              <TabsTrigger value="future">Future</TabsTrigger>
              <TabsTrigger value="report">Reports</TabsTrigger>
            </TabsList>
            <TabsContent value="upload">
              <UploadSection onTabValueChange={setTabValue} jobId={jobId} setJobId={setJobId} />
            </TabsContent>
            <TabsContent value="historical">
              <HistoricalSection customers={baseData.customers} marketZones={baseData.market_zones} />
            </TabsContent>
            <TabsContent value="staged">
              <StagedSection customers={baseData.customers} marketZones={baseData.market_zones} />
            </TabsContent>
            <TabsContent value="exceptions">
              <ExceptionsSection marketZones={baseData.market_zones} />
            </TabsContent>
            <TabsContent value="future">
              <FutureSection customers={baseData.customers} marketZones={baseData.market_zones} />
            </TabsContent>
            <TabsContent value="report">
              <ReportSection />
            </TabsContent>
          </Tabs>
        </div>
      </section>
    );
}

function UploadSection({ onTabValueChange, jobId, setJobId }: { onTabValueChange: any, jobId: string | undefined, setJobId: any }) {
    const uploadState = useUploadState((state: any) => state);
    const jobStatusRes = useGetCreateJobStatus(jobId);
    const inputState: any = createJobState.useInputsState((state: any) => state);
    const submissionState: any = createJobState.useSubmissionState((state: any) => state);
    const onCreateJob = useCreateUploadJob();
    const { toast } = useToast();

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row gap-4 justify-start gap-2">

            <span className="flex flex-col gap-2">
            <Label>End Date (Required)</Label>
            <DatePicker
                value={uploadState.shipment_end}
                onChange={(date) => {
                    uploadState.setShipmentEnd(date)
                }}
                placeholder=""
                className="w-[200px]"
            />
            </span>

            </div>
            {FormLayout.RenderFullPrimitiveGridLayout({
                hideSkipButton: true,
                id: "CreateDestinationMessagingUploadJob",
                activeSchema: CreateJobFormSchema,
                totalFields: inputState.totalFields,
                formValues: inputState.formValues,
                updateFormValue: inputState.updateFormValue,
                formRelationships: inputState.formRelationships,
                updateFormRelationship: inputState.updateFormRelationship,
                isSubmittingDisabled: !!jobId,
                submissionState,
                externalReferences: {},
                onSubmitTo: async (item: any) => {
                    if (!!jobId) { return new SuccessResult("", {}); }

                    if (![".xlsx", ".xls"].some((extension: string) => item.contract_release_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                        toast({
                            title: "Invalid Contract Release File",
                            description: "Must be an Excel file",
                        });
                        return new ValidationError("", {});
                    }
                    if (![".xlsx", ".xls"].some((extension: string) => item.contract_dp_release_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                        toast({
                            title: "Invalid Contract Release DP File",
                            description: "Must be an Excel file",
                        });
                        return new ValidationError("", {});
                    }
                    if (![".csv"].some((extension: string) => item.freight_orders_file?.name?.toLocaleLowerCase()?.endsWith(extension))) {
                        toast({
                            title: "Invalid Freight Orders File",
                            description: "Must be a CSV file",
                        });
                        return new ValidationError("", {});
                    }

                    const createResult = await onCreateJob({
                        contract_release_filename: item.contract_release_file.name || "",
                        freight_orders_filename: item.freight_orders_file.name || "",
                        contract_dp_release_filename: item.contract_dp_release_file.name || "",
                        start_date_filter: uploadState.shipment_start ? moment(uploadState.shipment_start).format("YYYY-MM-DD") : undefined,
                        end_date_filter: uploadState.shipment_end ? moment(uploadState.shipment_end).format("YYYY-MM-DD") : "",
                    });

                    if (!createResult.success) {
                        return createResult;
                    }

                    const resultData = createResult.data;
                    setJobId(resultData.queuedJobId);

                    await UploadPresignedFile(resultData.freightUploadUrl, item.freight_orders_file);
                    await UploadPresignedFile(resultData.contractUploadUrl, item.contract_release_file);
                    await UploadPresignedFile(resultData.contractDpUploadUrl, item.contract_dp_release_file);

                    return createResult;
                },
                handleSubmitResponse: (result) => {
                    if (result.statusCode === 400) {
                        return;
                    } else if (!result.success) {
                        toast({
                            title: "Failed to upload files",
                            description: "",
                        });
                    } else {
                        toast({
                            title: "Queued - Please wait.",
                            description: "",
                        });
                    }
                }
            })}

            {jobId && <JobStatus jobStatus={jobStatusRes.data?.data?.job_status} setJobId={setJobId} onTabValueChange={onTabValueChange} />}

        </div>
    );
}

function JobStatus({ jobStatus, setJobId, onTabValueChange }: { jobStatus: string; setJobId: any; onTabValueChange: any }) {
    const className = "text-lg font-bold tracking-tight mb-4";

    if (!jobStatus) { return (<div className={className}><Loader /></div>) }
    if (jobStatus === "queued") { return (<div className={className}>Processing</div>) }
    if (jobStatus === "doesnt_exist") { return (<div className={className}>Failed to find files</div>) }
    if (jobStatus === "failed") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus === "halt") { return (<div className={className}>Failed to process</div>) }
    if (jobStatus !== "completed") { return (<div className={className}>Unknown</div>); }

    onTabValueChange("staged");
    setJobId(undefined);
    return (<div className={className}>Completed</div>);
}

function HistoricalSection({
    customers,
    marketZones,
  }:{
    customers: BaseInfo["customers"];
    marketZones: BaseInfo["market_zones"];
  }) {
      const messagesState = useHistoricalState((state: any) => state);
      const historicalDataRes = useListHistoricalData(moment(messagesState.sent_date).format("YYYY-MM-DD"));
      const loadingOrErrorUi = GenerateLoadingOrError([historicalDataRes])
  
      if (loadingOrErrorUi) {
          return loadingOrErrorUi;
      }

    if (!messagesState.sent_date) {
        return (<div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row justify-end items-center">
                <span className="flex flex-col gap-2">
                    <Label>Select a date to view historical messages</Label>
                </span>
            </div>
        </div>);
    }

    const { messages: historicalRows, internal_users: internalUsers } = historicalDataRes.data?.data || { messages: [], internal_users: [] };

    const stageRowCollectionMap = historicalRows.reduce((acc: any, row: HistoricalData) => {
        if (!acc[row.destination_id]) {
            acc[row.destination_id] = { commodities: [], rows: [] };
        }

        row.DestinationMessageReceivers.forEach((receiver: HistoricalMessageReceiver) => {
            const commoditySplit = receiver.commodity.split("_");
            commoditySplit.forEach((commodity: string) => {
                if (!acc[row.destination_id].commodities.includes(commodity)) {
                    acc[row.destination_id].commodities.push(commodity);
                }

                acc[row.destination_id].rows.push({
                    ...receiver,
                    message: row.message || row?.content?.meta_data?.body || "",
                });
            });
        });

        return acc;
    }, {});

    const selectedDestinationFullCommoditiesList = stageRowCollectionMap[messagesState.selectedDestinationId]?.commodities || [];
    const orderedDestinations = Object.keys(stageRowCollectionMap).map((destinationId: string) => marketZones.find((marketZone: any) => marketZone.id === destinationId)).sort((a: any, b: any) => a.dtn_market_zone.localeCompare(b.dtn_market_zone));

    const selectedRow = stageRowCollectionMap[messagesState.selectedDestinationId]?.rows?.find((row: HistoricalMessageReceiver) => row.commodity.split("_").includes(messagesState.selectedCommodity)) || "";
    const message = selectedRow?.message || "";

    return (<div className="mt-4 flex flex-col gap-4">
        <div className="flex flex-row justify-start items-center">
            <span className="flex flex-col gap-2">
            <Label>Message Date</Label>
            <DatePicker
                value={messagesState.sent_date}
                onChange={(date) => {
                    messagesState.setSentDate(date)
                }}
                placeholder=""
                className="w-[200px]"
            />
            </span>
        </div>
        {historicalRows.length ? (
        <>
            <div className="flex flex-row gap-4 items-end">
                <Textarea 
                    className="flex-1"
                    placeholder="Select a Destination and Commodity to view message"
                    disabled
                    value={message}
                />
            </div>
            <div className="mt-4 grid grid-cols-3 gap-4 h-full bg-gray-100 divide-x divide-gray-200 p-2">
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-3">Destination</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {orderedDestinations?.map((row: any) => (
                        <Button
                            key={row.id}
                            variant="ghost"
                            className={cn(
                                "justify-start h-auto py-2 px-3",
                                messagesState.selectedDestinationId === row.id ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                            )}
                            onClick={() => {
                                if (messagesState.selectedDestinationId === row.id) {
                                    messagesState.setSelectedDestinationId(undefined);
                                    messagesState.setSelectedCommodity(undefined);
                                } else {
                                    messagesState.setSelectedDestinationId(row.id);
                                    messagesState.setSelectedCommodity(undefined);
                                }
                            }}
                        >
                            {row?.dtn_market_zone}
                        </Button>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-2">Commodity</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {selectedDestinationFullCommoditiesList.map((commodity: string) => (
                        <div 
                            key={commodity}
                            className="flex items-center gap-2 py-1 px-2"
                        >
                            <Button
                                key={`History:${commodity}`}
                                variant="ghost"
                                className={cn(
                                    "justify-start h-auto py-2 px-3",
                                    messagesState.selectedCommodity === commodity ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                                )}
                                onClick={() => {
                                    if (messagesState.selectedCommodity === commodity) {
                                        messagesState.setSelectedCommodity(undefined);
                                    } else {
                                        messagesState.setSelectedCommodity(commodity);
                                    }
                                }}
                            >
                            {CommodityOptions.find((option: any) => option.value === commodity)?.label}
                            </Button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold">Producer/Freight/Internal User</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {stageRowCollectionMap[messagesState.selectedDestinationId]?.rows?.filter((row: HistoricalMessageReceiver) => row.commodity.split("_").includes(messagesState.selectedCommodity)).map((row: HistoricalMessageReceiver) => (
                        <div 
                            key={row.receiver_id}
                            className="flex items-center gap-2 py-1"
                        >
                            <span className={cn(
                                "flex-1",
                            )}>
                                { row.receiver_id ? customers.find((customer: any) => customer.id === row.receiver_id)?.long_name : internalUsers.find((user: InternalUser) => user.id === row.internal_user_id)?.name }
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
        ) :
          (
            <div className="mt-4 grid grid-cols-3 gap-4 h-full">
              Found no messages for Date
            </div>
          )
        }
    </div>);
}

function StagedSection({
  customers,
  marketZones,
}:{
  customers: BaseInfo["customers"];
  marketZones: BaseInfo["market_zones"];
}) {
    const internalUsersRes = useListInternalUsers();
    const messagesState = useMessagesState((state: any) => state);
    const { setSelectedCommodities, setSelectedDestinationId } = useMessagesState((state: any) => ({ setSelectedCommodities: state.setSelectedCommodities, setSelectedDestinationId: state.setSelectedDestinationId }));
    const stagedDataRes = useListStagedData({});
    const loadingOrErrorUi = GenerateLoadingOrError([stagedDataRes, internalUsersRes])
    const updateEnabledStatus = useUpdateEnabledStatus();
    const updateEnabledBulkStatus = useUpdateEnabledBulkStatus();
    const sendMessage = useSendMessage();

    useEffect(() => {
        if (!!internalUsersRes?.data?.data) {
            const internalUsers = internalUsersRes.data?.data || [];
            messagesState.setSelectedInternalUsers(internalUsers.reduce((acc: Record<string, boolean>, user: InternalUser) => ({
                ...acc,
                [user.id]: true
            }), {} as Record<string, boolean>));
        }
    }, [!!internalUsersRes?.data?.data]);

    if (loadingOrErrorUi) {
        return loadingOrErrorUi;
    }

    const internalUsers = internalUsersRes.data?.data || [];

    const { data: stagedRows } = stagedDataRes.data?.data || { exceptions: [], data: [] };

    const stageRowCollectionMap = stagedRows.reduce((acc: any, row: StagedRow) => {
        if (!acc[row.data.destination_id]) {
            acc[row.data.destination_id] = { commodities: [], rows: [], producer_or_carrier_ids: {} };
        }

        if (!acc[row.data.destination_id].commodities.includes(row.data.commodity)) {
            acc[row.data.destination_id].commodities.push(row.data.commodity);
        }

        if (!!acc[row.data.destination_id].producer_or_carrier_ids?.[row.data.producer_or_carrier_id]?.[row.data.commodity]) {
            return acc;
        }

        if (!acc[row.data.destination_id].producer_or_carrier_ids[row.data.producer_or_carrier_id]) {
            acc[row.data.destination_id].producer_or_carrier_ids[row.data.producer_or_carrier_id] = {};
        }
        if (!acc[row.data.destination_id].producer_or_carrier_ids[row.data.producer_or_carrier_id][row.data.commodity]) {
            acc[row.data.destination_id].producer_or_carrier_ids[row.data.producer_or_carrier_id][row.data.commodity] = true;
        }

        acc[row.data.destination_id].rows.push(row);
        return acc;
    }, {});

    const selectedDestinationFullCommoditiesList = (stageRowCollectionMap[messagesState.selectedDestinationId]?.commodities || []).sort();
    const orderedDestinations = Object.keys(stageRowCollectionMap).map((destinationId: string) => marketZones.find((marketZone: any) => marketZone.id === destinationId)).sort((a: any, b: any) => a.dtn_market_zone.localeCompare(b.dtn_market_zone));
    const commodityRows = stageRowCollectionMap[messagesState.selectedDestinationId]?.rows
        ?.filter((row: StagedRow) => messagesState.selectedCommodities?.some((commodity: string) => row.data.commodity.split("_").includes(commodity)))
        .sort((a: StagedRow, b: StagedRow) => findByKey("id", a.data.producer_or_carrier_id)(customers).long_name.localeCompare(findByKey("id", b.data.producer_or_carrier_id)(customers).long_name));

    return (<div className="mt-4 flex flex-col gap-4">
        <div className="flex flex-row justify-end items-center gap-2">
        </div>
        {stagedRows.length ? (
        <>
            <div className="flex flex-row gap-4 items-end">
                <Textarea 
                    className="flex-1"
                    placeholder="Enter freight/producer message here..."
                    disabled={!messagesState.selectedDestinationId || messagesState.selectedCommodities?.length === 0}
                    value={messagesState.messages[messagesState.selectedDestinationId] || ''}
                    onChange={(e) => messagesState.setFilterState(messagesState.selectedDestinationId, e.target.value)}
                />
                <div className="flex flex-col gap-2">
                    <Label>Schedule Future Send</Label>
                    <span className="flex flex-row gap-2">
                        <DatePicker
                        value={messagesState.scheduledDate}
                        onChange={(date) => {
                            messagesState.setScheduledDate(date)
                        }}
                        placeholder=""
                        className="w-[200px]"
                        minDate={new Date()} // Prevents selecting past dates
                        />
                        <TimePicker
                            value={messagesState.scheduledTime}
                            onChange={(time) => {
                                messagesState.setScheduledTime(time)
                            }}
                        />
                        <span className="text-sm flex flex-col gap-2">
                            <Label>Test</Label>
                            <Checkbox
                                disabled={false}
                                checked={messagesState.isTest}
                                onCheckedChange={(checked: boolean) => {
                                    messagesState.setIsTest(checked);
                                }}
                            />
                        </span>
                    </span>
                    <Button
                        disabled={
                            !messagesState.selectedDestinationId || 
                            !(messagesState.selectedCommodities?.length > 0) ||
                            (messagesState.messages[messagesState.selectedDestinationId]?.length || 0) < 5 ||
                            messagesState.isCommunicating
                        }
                        onClick={async () => {
                            if (messagesState.isCommunicating) { return; }
                            if (!!messagesState.scheduledDate && moment(messagesState.scheduledDate).isSameOrBefore(moment().endOf("day"))) {
                                toast({
                                    title: "Invalid date",
                                    description: "Date must be in the future",
                                });
                                return;
                            }
                            messagesState.setIsCommunicating(true);
                            messagesState.clearFilterKey(messagesState.selectedDestinationId);

                            const formattedDate = messagesState.scheduledDate ? moment(messagesState.scheduledDate).format("YYYY-MM-DD") : undefined;   
                            
                            // Get selected internal user IDs
                            const selectedInternalUserIds = Object.entries(messagesState.selectedInternalUsers)
                                .filter(([_, isSelected]) => isSelected)
                                .map(([id]) => id);

                            const sendResult = await sendMessage({
                                is_test: messagesState.isTest,
                                destination_id: messagesState.selectedDestinationId,
                                commodities: messagesState.selectedCommodities,
                                send_at: formattedDate,
                                send_at_time: messagesState.scheduledTime,
                                message: messagesState.messages[messagesState.selectedDestinationId] || "",
                                internal_user_ids: selectedInternalUserIds,
                            });
                            
                            if (sendResult.success) {
                                toast({
                                    title: "Message sent",
                                    description: messagesState.scheduledDate ? 
                                        `Scheduled for ${formattedDate}` : 
                                        "Sending now",
                                });
                            } else {
                                toast({
                                    title: "Failed to send message",
                                    description: "",
                                });
                            }
                            
                            messagesState.setSelectedCommodities([]);
                            messagesState.setSelectedDestinationId(undefined);
                            messagesState.setIsCommunicating(false);
                            messagesState.setScheduledDate(undefined); // Reset date after sending
                        }}
                    >
                        {messagesState.scheduledDate ? 'Schedule' : 'Send'}
                    </Button>
                </div>
            </div>
            <div className="mt-4 grid grid-cols-4 gap-4 h-full bg-gray-100 divide-x divide-gray-200 p-2">
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-3">Destination</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {orderedDestinations?.map((row: any) => (
                        <Button
                            key={`Destination:${row.id}`}
                            variant="ghost"
                            className={cn(
                                "justify-start h-auto py-2 px-3",
                                messagesState.selectedDestinationId === row.id ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                            )}
                            onClick={() => {
                                if (messagesState.selectedDestinationId === row.id) {
                                    messagesState.setSelectedDestinationId(undefined);
                                    messagesState.setSelectedCommodities([]);
                                } else {
                                    messagesState.setSelectedDestinationId(row.id);

                                    if (stageRowCollectionMap[row.id]?.commodities.length === 1) {
                                        messagesState.setSelectedCommodities(stageRowCollectionMap[row.id].commodities);
                                    } else if (stageRowCollectionMap[row.id]?.commodities.length > 1) {
                                        messagesState.setSelectedCommodities([stageRowCollectionMap[row.id].commodities.sort()[0]]);
                                    }
                                }
                            }}
                        >
                            {row?.dtn_market_zone}
                        </Button>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <div className="flex items-center gap-2 px-2">
                            <input
                                type="checkbox"
                                disabled={messagesState.isCommunicating || !messagesState.selectedDestinationId}
                                checked={selectedDestinationFullCommoditiesList.length > 0 && 
                                       selectedDestinationFullCommoditiesList.every((commodity: string) => messagesState.selectedCommodities.includes(commodity))}
                                onChange={(e) => {
                                    if (messagesState.isCommunicating) return;
                                    messagesState.setIsCommunicating(true);

                                    const newSelectedCommodities = e.target.checked
                                        ? selectedDestinationFullCommoditiesList
                                        : [];
                                    messagesState.setSelectedCommodities(newSelectedCommodities);
                                    messagesState.setIsCommunicating(false);
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <h3 className="font-semibold">Commodity</h3>
                        </div>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {selectedDestinationFullCommoditiesList.map((commodity: string) => (
                        <div 
                            key={`DestinationCommodity:${commodity}`}
                            className="flex items-center gap-2 py-1 px-2"
                        >
                                <input
                                    type="checkbox"
                                checked={messagesState.selectedCommodities.includes(commodity)}
                                onChange={(e) => {
                                    if (messagesState.isCommunicating) return;
                                    messagesState.setIsCommunicating(true);

                                    const newSelectedCommodities = e.target.checked
                                        ? [...messagesState.selectedCommodities, commodity]
                                        : (messagesState.selectedCommodities || []).filter((c: string) => c !== commodity);
                                    messagesState.setSelectedCommodities(newSelectedCommodities);
                                    messagesState.setIsCommunicating(false);
                                }}
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            <span className="flex-1">
                                {CommodityOptions.find((option: any) => option.value === commodity)?.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <input
                                type="checkbox"
                                disabled={messagesState.isCommunicating || !messagesState.selectedDestinationId}
                                checked={!commodityRows?.length ? false : commodityRows?.every((row: StagedRow) => row.data.is_enabled)}
                                onChange={async (e) => {
                                    if (messagesState.isCommunicating) return;
                                    messagesState.setIsCommunicating(true);

                                    const relevantRows = commodityRows;

                                    const updateResult = await updateEnabledBulkStatus(relevantRows.map((row: StagedRow) => row.id), e.target.checked);
                                    if (!updateResult.success) {
                                        toast({
                                            title: "Failed to update all statuses",
                                            description: "",
                                        });
                                    }

                                    messagesState.setIsCommunicating(false);
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <h3 className="font-semibold">Producer/Freight</h3>
                        </div>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {commodityRows?.map((row: FutureRow) => (
                        <div 
                            key={row.data.producer_or_carrier_id}
                            className="flex items-center gap-2 py-1"
                        >
                            {messagesState.isCommunicating && <Loader className="h-4 w-4 animate-spin" />}
                            {!messagesState.isCommunicating && (
                            <input
                                type="checkbox"
                                checked={row.data.is_enabled}
                                onChange={async (e) => {
                                    if (messagesState.isCommunicating) { return; }
                                    messagesState.setIsCommunicating(true);

                                    const updateResult = await updateEnabledStatus(row.id, e.target.checked);
                                    if (!updateResult.success) {
                                        toast({
                                            title: "Failed to update status",
                                            description: "",
                                        });
                                    } else {
                                        toast({
                                            title: "Updated",
                                            description: "",
                                        });
                                    }   

                                    messagesState.setIsCommunicating(false);
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            )}
                            <span className={cn(
                                "flex-1",
                                !row.data.is_enabled && "text-red-500 line-through opacity-70"
                            )}>
                                { row.data.producer_or_carrier_id ? customers.find((customer: any) => customer.id === row.data.producer_or_carrier_id)?.long_name  : findByKey("id", row.data.internal_user_id!)(internalUsers)?.name }
                            </span>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <h3 className="font-semibold">Internal Users</h3>
                        </div>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {internalUsers.map((user: InternalUser) => (
                        <div 
                            key={user.id}
                            className="flex items-center gap-2 py-1"
                        >
                            <input
                                type="checkbox"
                                disabled={messagesState.isCommunicating}
                                checked={messagesState.selectedInternalUsers[user.id] || false}
                                onChange={(e) => {
                                    messagesState.setSelectedInternalUsers({
                                        ...messagesState.selectedInternalUsers,
                                        [user.id]: e.target.checked
                                    });
                                }}
                                className="h-4 w-4 rounded border-gray-300"
                            />
                            <span className="flex-1">
                                {user.name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
        ) :
          (
            <div className="mt-4 grid grid-cols-3 gap-4 h-full">
              Found no remaining groups to message
            </div>
          )
        }
    </div>);
}

function ExceptionsSection({ marketZones }: { marketZones: BaseInfo["market_zones"] }) {
    const stagedDataRes = useListStagedData({});
    const customersDataRes = useListCustomers();
    const [isMarketZoneOpen, setIsMarketZoneOpen] = useState(false);
    const [isCustomerModalOpen, setCustomerModalOpenStatus] = useState(false);
    const loadingOrErrorUi = GenerateLoadingOrError([stagedDataRes, customersDataRes])
    const populateMarketZoneWith: any = marketZoneCreateState.useInputsState((state: any) => state.populateWith);
    const setCustomer: any = useCustomerBearStore((state: any) => state.setCustomer);

    if (loadingOrErrorUi) {
        return loadingOrErrorUi;
    }

    const { exceptions } = stagedDataRes.data?.data || { exceptions: [], data: [] };

    // Group exceptions by type
    const groupedExceptions = exceptions.reduce((acc: any, exception: any) => {
        exception.data.exception_types.forEach((type: string) => {
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(exception);
        });
        return acc;
    }, {});

    const orderedExceptionTypes = ["market_zone", "producer_or_carrier", "no_phone_number", "no_pair"];
    const customers = customersDataRes.data?.data || [];

    return (
        <div className="space-y-8 mt-4">
           <MarketZoneCreateFormModal isOpen={isMarketZoneOpen} setIsOpen={setIsMarketZoneOpen} customers={customers} marketZones={marketZones} />
           <CustomerFormModal isOpen={isCustomerModalOpen} setIsOpen={setCustomerModalOpenStatus} />
           {orderedExceptionTypes.map((type: string) => {
                const exceptions = groupedExceptions[type] || [];
                if (!exceptions.length) {
                    return null;
                }
                switch(type) {
                    case "market_zone":
                        return (
                            <>
                                <h1 className="scroll-m-20 text-xl font-bold tracking-tight">
                                    Missing Market Zone
                                </h1>
                                <PrimitiveManagedTable schema={MarketZoneSchema} values={exceptions.map((exception: any) => ({
                                    id: exception.id, exception_id: exception.id, key: `${exception.id}_missing_market_zone`, dtn_market_zone: exception.data.original_market_zone, market_zone: exception.data.original_market_zone,
                                    exception_type: "market_zone",
                                }))} onRowClick={(item) => {
                                    setIsMarketZoneOpen(true);
                                    populateMarketZoneWith(item);
                                }} />
                            </>
                        );
                    case "producer_or_carrier":
                        return (
                            <>
                                <h1 className="scroll-m-20 text-xl font-bold tracking-tight">
                                    Missing Producer or Carrier
                                </h1>
                                <PrimitiveManagedTable schema={BaseCustomerSchema} values={exceptions.map((exception: any) => ({
                                    ...exception,
                                    ...exception.data,
                                    long_name: exception.data.original_producer_or_carrier_name,
                                    short_name: exception.data.original_producer_or_carrier_name,
                                    exception_id: exception.id,
                                    key: `${exception.id}_missing_producer_or_carrier`,
                                    exception_type: "producer_or_carrier",
                                }))} onRowClick={(item) => {
                                    setCustomer({
                                        ...(item as any || {}),
                                        is_commercial: false,
                                        data: undefined,
                                        id: undefined,
                                    } as any);
                                    setCustomerModalOpenStatus(true);
                                }} />
                            </>
                        );
                    case "no_phone_number":
                        return (
                            <>
                                <h1 className="scroll-m-20 text-xl font-bold tracking-tight">
                                    Missing Destination Phone Number
                                </h1>
                                <PrimitiveManagedTable schema={BaseCustomerSchema} values={exceptions.map((exception: any) => {
                                    const customer = customers.find((customer: any) => customer.id === exception.data.producer_or_carrier_id) || {};
                                    return ({
                                        ...(customer),
                                        is_commercial: customer.is_commercial || false,
                                        id: customer.id,
                                        exception_id: exception.id,
                                        key: `${exception.id}_missing_phone_number`,
                                        exception_type: "no_phone_number",
                                    });
                                })} onRowClick={(item) => {
                                    setCustomer({
                                        ...(item as any || {}),
                                        data: undefined,
                                    })
                                    setCustomerModalOpenStatus(true);
                                }} />
                            </>
                        );
                    default:
                        return null;

                }
           })}
        </div>
    );
}

function FutureSection({ customers, marketZones }: { customers: BaseInfo["customers"], marketZones: BaseInfo["market_zones"] }) {
    const futureDataRes = useListFutureData();
    const internalUsersRes = useListInternalUsers();
    const futureState: any = useFutureState();
    const loadingOrErrorUi = GenerateLoadingOrError([futureDataRes, internalUsersRes])
    const deleteFutureMessage = useCancelFutureMessage();
    const updateFutureMessage = useUpdateFutureMessage();
    const { toast } = useToast();
    const [selectedMessage, setSelectedMessage] = useState("");
    
    if (loadingOrErrorUi) {
        return loadingOrErrorUi;
    }

    const futureRows = (futureDataRes.data?.data || []);
    const internalUsers = internalUsersRes.data?.data || [];

    const stageRowCollectionMap = futureRows.reduce((acc: any, row: any) => {
        if (!acc[row.data.destination_id]) {
            acc[row.data.destination_id] = { commodities: [], rows: [], producer_or_carrier_ids: {} };
        }

        row.data.commodity.split("_").forEach((commodity: string) => {
            if (!acc[row.data.destination_id].commodities.includes(commodity)) {
                acc[row.data.destination_id].commodities.push(commodity);
            }

            const producer_or_carrier_or_internal_id = row.data.internal_user_id || row.data.producer_or_carrier_id;
            if (!!acc[row.data.destination_id].producer_or_carrier_ids?.[producer_or_carrier_or_internal_id]?.[commodity]) {
                return acc;
            }

            if (!acc[row.data.destination_id].producer_or_carrier_ids[producer_or_carrier_or_internal_id]) {
                acc[row.data.destination_id].producer_or_carrier_ids[producer_or_carrier_or_internal_id] = {};
            }
            if (!acc[row.data.destination_id].producer_or_carrier_ids[producer_or_carrier_or_internal_id][commodity]) {
                acc[row.data.destination_id].producer_or_carrier_ids[producer_or_carrier_or_internal_id][commodity] = true;
            }

            acc[row.data.destination_id].rows.push(row);
        });

        return acc;
    }, {});

    const selectedDestinationFullCommoditiesList = stageRowCollectionMap[futureState.selectedDestinationId]?.commodities || [];
    const orderedDestinations = Object.keys(stageRowCollectionMap).map((destinationId: string) => marketZones.find((marketZone: any) => marketZone.id === destinationId)).sort((a: any, b: any) => a.dtn_market_zone.localeCompare(b.dtn_market_zone));
    const commodityRows = stageRowCollectionMap[futureState.selectedDestinationId]?.rows
        ?.filter((row: any) => row.data.commodity.split("_").includes(futureState.selectedCommodity))
        ?.sort((a: any, b: any) => {
            const aProducerId = a.data.producer_or_carrier_id;
            const bProducerId = b.data.producer_or_carrier_id;
            const aInternalUserId = a.data.internal_user_id;
            const bInternalUserId = b.data.internal_user_id;

            // If one has producer ID and other has internal ID, producer ID sorts first
            if (aProducerId && !bProducerId) return -1;
            if (!aProducerId && bProducerId) return 1;

            // If both have producer IDs, sort by customer long_name
            if (aProducerId && bProducerId) {
                const aCustomer = findByKey("id", aProducerId)(customers);
                const bCustomer = findByKey("id", bProducerId)(customers);
                return aCustomer.long_name.localeCompare(bCustomer.long_name);
            }

            // If both have internal IDs, sort by internal user name
            if (aInternalUserId && bInternalUserId) {
                const aUser = findByKey("id", aInternalUserId)(internalUsers);
                const bUser = findByKey("id", bInternalUserId)(internalUsers);
                return aUser.name.localeCompare(bUser.name);
            }

            return 0;
        });
    const selectedFirstRow = futureState.selectedDestinationId && futureState.selectedCommodity &&
        stageRowCollectionMap[futureState.selectedDestinationId]?.rows?.find((row: any) => row.data.commodity.split("_").includes(futureState.selectedCommodity));

    return (<div className="mt-4 flex flex-col gap-4">
        {futureRows.length ? (
        <>
            <div className="flex flex-row gap-4 items-end items-center">
                <Textarea 
                    className="flex-1"
                    placeholder="Select a Destination and Commodity to view message"
                    disabled={!futureState.selectedDestinationId || !futureState.selectedCommodity}
                    onChange={(e) => setSelectedMessage(e.target.value)}
                    value={selectedMessage}
                />
                <div className="flex flex-col gap-2 mb-4">
                    <Label>Scheduled For</Label>
                    <span className="flex flex-row gap-2">
                        <DatePicker
                            value={selectedFirstRow?.data?.poll_skip_to ? new Date(selectedFirstRow.data.poll_skip_to) : undefined}
                            onChange={() => {}}
                            placeholder=""
                            className="w-[200px]"
                            disabled={true}
                        />
                        <TimePicker
                            value={selectedFirstRow?.data?.poll_skip_to ? 
                                moment.tz(selectedFirstRow.data.poll_skip_to, CommonDestinationTimezone).format("HH:mm:ss") : 
                                ""}
                            onChange={() => {}}
                            disabled={true}
                        />
                    </span>
                </div>
                        <Button
                            variant="secondary"
                            disabled={!selectedMessage?.trim()}
                            onClick={async () => {
                                if (!selectedFirstRow || !selectedMessage?.trim()) return;
                                if (futureState.isDeleting) return;
                                futureState.setIsDeleting(true);

                                const result = await updateFutureMessage({ parentJobId: selectedFirstRow.data.parentJobId, message: selectedMessage?.trim() });
                                if (result.success) {
                                    futureState.setSelectedCommodity(undefined);
                                    setSelectedMessage("");
                                    toast({
                                        title: "Updated",
                                        description: "The scheduled message group has been updated",
                                    });
                                } else {
                                    toast({
                                        title: "Failed",
                                        description: "An error occurred while updating the message group",
                                    });
                                }
                                futureState.setIsDeleting(false);
                            }}
                        >
                            Update
                        </Button>
                        <Button
                            variant="destructive"
                            disabled={!selectedFirstRow}
                            onClick={async () => {
                                if (!selectedFirstRow) return;
                                if (futureState.isDeleting) return;
                                futureState.setIsDeleting(true);

                                const result = await deleteFutureMessage(selectedFirstRow.data.parentJobId);
                                if (result.success) {
                                    setSelectedMessage("");
                                    toast({
                                        title: "Message deleted",
                                        description: "The scheduled message group has been deleted",
                                    });
                                    futureState.setSelectedDestinationId(undefined);
                                    futureState.setSelectedCommodity(undefined);
                                } else {
                                    toast({
                                        title: "Failed",
                                        description: "An error occurred while deleting the message group",
                                    });
                                }
                                futureState.setIsDeleting(false);
                            }}
                        >
                            Delete
                        </Button>
            </div>
            <div className="mt-4 grid grid-cols-3 gap-4 h-full bg-gray-100 divide-x divide-gray-200 p-2">
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-3">Destination</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {orderedDestinations?.map((row: any) => (
                        <Button
                            key={row.id}
                            variant="ghost"
                            className={cn(
                                "justify-start h-auto py-2 px-3",
                                futureState.selectedDestinationId === row.id ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                            )}
                            onClick={() => {
                                if (futureState.selectedDestinationId === row.id) {
                                    futureState.setSelectedDestinationId(undefined);
                                    futureState.setSelectedCommodity(undefined);
                                    setSelectedMessage("");
                                } else {
                                    futureState.setSelectedDestinationId(row.id);
                                    futureState.setSelectedCommodity(undefined);
                                    setSelectedMessage("");
                                }
                            }}
                        >
                            {row?.dtn_market_zone}
                        </Button>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold px-2">Commodity</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {selectedDestinationFullCommoditiesList.map((commodity: string) => (
                        <div 
                            key={commodity}
                            className="flex items-center gap-2 py-1 px-2"
                        >
                            <Button
                                key={`History:${commodity}`}
                                variant="ghost"
                                className={cn(
                                    "justify-start h-auto py-2 px-3",
                                    futureState.selectedCommodity === commodity ? "bg-blue-500 hover:bg-blue-600 text-white" : ""
                                )}
                                onClick={() => {
                                    if (futureState.selectedCommodity === commodity) {
                                        futureState.setSelectedCommodity(undefined);
                                        setSelectedMessage("");
                                    } else {
                                        const selectedRow = stageRowCollectionMap[futureState.selectedDestinationId]?.rows?.find((row: any) => row.data.commodity.split("_").includes(commodity));
                                        const message = selectedRow?.data?.content?.meta_data?.body || "";
                                        futureState.setSelectedCommodity(commodity);
                                        setSelectedMessage(message);
                                    }
                                }}
                            >
                            {CommodityOptions.find((option: any) => option.value === commodity)?.label}
                            </Button>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col h-full px-2">
                    <div className="mb-4">
                        <h3 className="font-semibold">Producer/Freight/Internal User</h3>
                        <div className="h-[1px] bg-gray-200 mt-2 mb-3 mx-[-8px]"></div>
                    </div>
                    {commodityRows?.map((row: any) => (
                        <div 
                            key={row.data.producer_or_carrier_id}
                            className="flex items-center gap-2 py-1"
                        >
                            <span className={cn(
                                "flex-1",
                            )}>
                                {row.data.producer_or_carrier_id ? customers.find((customer: any) => customer.id === row.data.producer_or_carrier_id)?.long_name  : findByKey("id", row.data.internal_user_id!)(internalUsers)?.name }
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
        ) :
          (
            <div className="mt-4 grid grid-cols-3 gap-4 h-full">
                Found no Producer/Carrier messages scheduled for sending.
            </div>
          )
        }
    </div>);
}

function ReportSection() {
    const { toast } = useToast();
    const [dateSent, setDateSent] = useState<Date | null>(new Date());
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [generatedReportUrl, setGeneratedReportUrl] = useState<string | null>(null);
    const generateTwilioReport = useGenerateTwilioReport();

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row gap-4 items-end items-center">
                <DatePicker
                    value={dateSent as any}
                    onChange={(date) => setDateSent(date as any)}
                />
                <Button
                    variant="secondary"
                    disabled={isGeneratingReport}
                    onClick={async () => {
                        if (isGeneratingReport) return;
                        setIsGeneratingReport(true);

                        const result = await generateTwilioReport(moment.tz(dateSent || new Date() as any, CommonDestinationTimezone).startOf("day").add(1, "hour").toDate());
                        if (result.success) {
                            setGeneratedReportUrl(result.data.url);
                            toast({
                                title: "Report Generated",
                                description: "",
                            });
                        } else {
                            toast({
                                title: "Failed",
                                description: "An error occurred while generating the report",
                            });
                        }

                        setIsGeneratingReport(false);
                    }}
                >
                    Generate Report
                </Button>
            </div>

            {isGeneratingReport && <div>Generating report...</div>}
            {generatedReportUrl && <div><a href={generatedReportUrl} target="_blank" rel="noreferrer">View Report</a></div>}
        </div>
    );
}
