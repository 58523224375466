import { create } from "zustand";
import { useCallback, useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListLocations,
    Location,
    useCreateLocation,
    useUpdateLocation,
} from "../../lib/api/generalContract";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";
import { fetchNewsArticlesPage, useListFeeds, useListNewsArticles } from "@/lib/api/news";
import { Label } from "../../components/ui/label";
import { MultiSelect } from "../../components/ui/selectMulti";
import { Input } from "../../components/ui/input";
import useDebounce from "../otc/useDebounce";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from "../../components/ui/dialog";
  import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../../components/ui/accordion";
  import { Badge } from "../../components/ui/badge";

interface AIMetaData {
data: {
    summary: string;
    unknowns: Record<string, any>;
    sentiment: string;
    commodities: string[];
    skip_reason: string;
    topic_group: string;
    urgent_factor: number;
    important_factor: number;
    additional_insights: Record<string, { [key: string]: string }>;
    reasoning_breakouts: Record<string, { [key: string]: string }>;
};
}

interface AIMetaDataDialogProps {
isOpen: boolean;
onClose: () => void;
data?: AIMetaData;
article_title?: string;
}

interface FilterState {
  feedIds: string[];
  dateRange: {
    start?: string;
    end?: string;
  };
  urgencyFactor: {
    min?: number;
    max?: number;
  };
  importanceFactor: {
    min?: number;
    max?: number;
  };
  setFeedIds: (ids: string[]) => void;
  setDateRange: (range: { start?: string; end?: string }) => void;
  setUrgencyFactor: (factor: { min?: number; max?: number }) => void;
  setImportanceFactor: (factor: { min?: number; max?: number }) => void;
}

const useFilterStore = create<FilterState>((set) => ({
  feedIds: [],
  dateRange: {
    start: moment().subtract(2, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  urgencyFactor: {
    min: 7,
    max: 10,
  },
  importanceFactor: {
    min: 7,
    max: 10,
  },
  setFeedIds: (ids) => set({ feedIds: ids }),
  setDateRange: (range) => set({ dateRange: range }),
  setUrgencyFactor: (factor) => set({ urgencyFactor: factor }),
  setImportanceFactor: (factor) => set({ importanceFactor: factor }),
}));

const ArticleFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    feed_id: { label: "Feed", options_reference_id: "FeedOptions", input_type: InputType.Enum, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original, externalReferences }: any) => { return externalReferences.FeedOptions.find((option: any) => option.value === original.feed_id)?.label || "Unknown" } },
    article_url: { label: "Article URL", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return <a key={original.id} href={original.article_url} target="_blank" className="text-blue-500 underline">{original.article_title}</a> } },
    article_date: { label: "Article Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return moment.tz(original.article_date, CommonDestinationTimezone).format("MM/DD/YYYY") } },

    urgency_factor: { label: "Urgency Score", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.ai_meta_data?.data?.urgent_factor || "Unknown" } },
    important_factor: { label: "Important Score", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.ai_meta_data?.data?.important_factor || "Unknown" } },
};

export function NewsArticlesDashboard() {
    const [page, setPage] = useState(1);
    const [isAIMetaDataDialogOpen, setIsAIMetaDataDialogOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState<any | undefined>(undefined);
    const feedsRes: any = useListFeeds();
    const filters = useFilterStore(
        useCallback((state) => ({
            urgency_factor: state.urgencyFactor,
            importance_factor: state.importanceFactor,
            feed_ids: state.feedIds,
            article_date_range: state.dateRange,
        }), [])
    );
    const debouncedFilters = useDebounce(filters, 300);
    const newsArticleRes: any = useListNewsArticles({
      page,
      filters: debouncedFilters as any,
      queryFn: () => fetchNewsArticlesPage(page, debouncedFilters as any)
    });
    const loadingOrErrorUi = GenerateLoadingOrError([newsArticleRes, feedsRes])

    if (loadingOrErrorUi) {
        return (
            <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
               <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                   Articles Dashboard
               </h1>
               {loadingOrErrorUi}
           </section>
       );
   }

   const feeds = feedsRes.data?.data || [];
   const isLoading = [newsArticleRes, feedsRes].some((result) => result.isFetching)
   const info = newsArticleRes.data?.data || {};

   return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Articles Dashboard
        </h1>
      </div>
      <div className="container flex flex-col md:flex-col items-start gap-4">
        <FilterOptions feeds={feeds} />
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col">
        <PrimitiveManagedTable
            tableHeight="600px"
            externalReferences={{
                FeedOptions: genIdOptionList(feeds, "source_name")
            }}
            schema={ArticleFormSchema}
            values={info.data}
            onRowClick={(item: any) => {
                setSelectedArticle(item);
                setIsAIMetaDataDialogOpen(true);
            }}
        />
        </div>
      <div className="container flex items-center justify-between mt-4">
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page <= 1 || isLoading}
        >
          {isLoading ? <Loader /> : <span>Previous</span>}
        </Button>

        <span>
          Page {page} of {info.totalPages}
        </span>

        <Button
          onClick={() => setPage(page + 1)}
          disabled={page >= info.totalPages || isLoading}
        >
          {isLoading ? <Loader /> : <span>Next</span>}
        </Button>
      </div>
      </div>
      <AIMetaDataDialog isOpen={isAIMetaDataDialogOpen} onClose={() => setIsAIMetaDataDialogOpen(false)} data={selectedArticle?.ai_meta_data} article_title={selectedArticle?.article_title} />
    </>
  );
}

function FilterOptions({ feeds }: { feeds: any[] }) {
    const { feedIds, dateRange, urgencyFactor, importanceFactor, setFeedIds, setDateRange, setUrgencyFactor, setImportanceFactor } = useFilterStore();

    return (
        <>
        <div className="flex flex-row gap-2">

        <div className="space-y-2">
          <Label>Urgency Factor</Label>
          <div className="grid grid-cols-2 gap-2">
            <Input
              type="number"
              value={urgencyFactor.min}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setUrgencyFactor({ ...urgencyFactor, min: value });
              }}
              placeholder="Min"
              min={1}
              max={10}
            />
            <Input
              type="number"
              value={urgencyFactor.max}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setUrgencyFactor({ ...urgencyFactor, max: value });
              }}
              placeholder="Max"
              min={1}
              max={10}
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label>Importance Factor</Label>
          <div className="grid grid-cols-2 gap-2">
            <Input
              type="number"
              value={importanceFactor.min}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setImportanceFactor({ ...importanceFactor, min: value });
              }}
              placeholder="Min"
              min={1}
              max={10}
            />
            <Input
              type="number"
              value={importanceFactor.max}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setImportanceFactor({ ...importanceFactor, max: value });
              }}
              placeholder="Max"
              min={1}
              max={10}
            />
          </div>

        </div>
        </div>
        <div className="flex flex-row gap-2">
        <div className="space-y-2">
          <Label>Date Range</Label>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 ">
            <Input
              type="date"
              value={dateRange.start}
              onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
              placeholder="Start date"
            />
            <Input
              type="date"
              value={dateRange.end}
              onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
              placeholder="End date"
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label>Feeds</Label>
          <MultiSelect
            options={feeds.map((feed: any) => ({ 
              label: feed.source_name, 
              value: feed.id 
            }))}
            values={feedIds}
            setValues={(newValue) => {
                if (Array.isArray(newValue)) {
                    setFeedIds(newValue);
                } else if (typeof newValue === 'function') {
                    setFeedIds(newValue(feedIds));
                }
            }}
          />
        </div>
        </div>
        </>
    )
}


export function AIMetaDataDialog({ isOpen, onClose, data, article_title }: AIMetaDataDialogProps) {
    const { data: metadata } = data || { metadata: undefined };
    
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">Article Analysis</DialogTitle>
          </DialogHeader>
  
          { metadata && (
            <div className="space-y-6 py-4">
              {/* Summary Section */}
            <div>
              <h2 className="font-medium mb-2">{article_title}</h2>
            </div>
            <div>
              <h3 className="font-medium mb-2">Summary</h3>
              <p className="text-sm text-gray-600">{metadata.summary}</p>
            </div>
  
            {/* Key Metrics */}
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <Badge variant="outline">Urgency: {metadata.urgent_factor}/10</Badge>
                <Badge variant="outline">Importance: {metadata.important_factor}/10</Badge>
                <Badge>{metadata.sentiment}</Badge>
              </div>
            </div>
  
            {/* Commodities */}
            <div>
              <h3 className="font-medium mb-2">Commodities</h3>
              <div className="flex gap-2">
                {metadata.commodities.map((commodity) => (
                  <Badge key={commodity} variant="secondary">
                    {commodity.replace('_', ' ')}
                  </Badge>
                ))}
              </div>
            </div>
  
            {/* Insights & Reasoning */}
            <Accordion type="single" collapsible className="w-full">
              {/* Additional Insights */}
              {Object.entries(metadata.additional_insights).map(([category, insights]) => (
                <AccordionItem key={`insights-${category}`} value={`insights-${category}`}>
                  <AccordionTrigger className="text-sm font-medium">
                    Additional Insights: {category.replace('_', ' ')}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-2">
                        { typeof insights === "string" && (
                            <div className="text-sm">
                            {insights}
                            </div>
                        )}
                        { typeof insights === "object" && (
                            Object.entries(insights).map(([key, value]) => (
                                <div key={key} className="text-sm">
                                    <span className="font-medium">{key.replace('_', ' ')}: </span>
                                    {value}
                                </div>
                            ))
                        )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
  
              {/* Reasoning Breakouts */}
              {Object.entries(metadata.reasoning_breakouts).map(([category, reasoning]) => (
                <AccordionItem key={`reasoning-${category}`} value={`reasoning-${category}`}>
                  <AccordionTrigger className="text-sm font-medium">
                    Detailed Analysis: {category.replace('_', ' ')}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-2">
                      {Object.entries(reasoning).map(([key, value]) => (
                        <div key={key} className="text-sm">
                          <span className="font-medium">{key.replace('_', ' ')}: </span>
                          {value}
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }


