import { PossiblePackage, PossibleRole, PermissionType, doesUserPackageHavePermissionTypes, doesUserHavePackages, getUserPackages, getUserRole } from "lhc_permissions";
export { PossiblePackage, PossibleRole, PermissionType, doesUserPackageHavePermissionTypes, doesUserHavePackages, getUserPackages, getUserRole }

export function getUserDbId(member: any): string {
  return member?.trusted_metadata["db_user_id"]!;
}

export function getUserStytchName(member: any): string {
  return member?.name! || "";
}
