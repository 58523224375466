import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import { z } from "zod";
import { BaseDbObject } from "../models/db";
import {
    fetchApiGet,
} from "./utils";

export const ArticleFilterValidation = z.object({
    feed_ids: z.array(z.string().uuid()).optional(),
    article_date_range: z.object({
        start: z.string().refine((val: string) => /^\d{4}-\d{2}-\d{2}$/.test(val), "Start date must be in YYYY-MM-DD format").optional(),
        end: z.string().refine((val: string) => /^\d{4}-\d{2}-\d{2}$/.test(val), "End date must be in YYYY-MM-DD format"),
    }).optional(),
    urgency_factor: z.object({
        min: z.number().optional(),
        max: z.number().optional(),
    }).optional(),
    importance_factor: z.object({
        min: z.number().optional(),
        max: z.number().optional(),
    }).optional(),
});

export interface ArticleFeed extends BaseDbObject {
    source_type: string;
    source_name: string;
    source_group?: string;
    source_url: string;
    enabled: boolean;
}

export interface ArticleAiMetaData {
    data: ArticleAiResult
}

export interface ArticleAiResult {
    summary: string;
    sentiment: string;
    skip_reason?: string;
    important_factor: number;
    urgent_factor: number;
    topic_group: string;
    commodities: string[];
    reasoning_breakouts: Record<string, any>;
    additional_insights: Record<string, any>;
    unknowns: Record<string, any>;
}

export interface NewsArticle extends BaseDbObject {
    feed_id: string;
    article_id: string;
    article_url?: string;
    article_title: string;
    article_date?: string;

    meta_data?: { keywords?: string[]; existing_data?: any; fetch_method?: string };
    ai_meta_data?: ArticleAiMetaData;

    skip_reason?: string;
}

export function useListFeeds() {
    const queryParams = new URLSearchParams();

    return useQuery({
        queryKey: ["feeds", queryParams.toString()],
        queryFn: () =>
            fetchApiGet<ArticleFeed[]>("news/feeds", queryParams),
        retry: 1,
    });
}

function buildFilters(page: number,filters: typeof ArticleFilterValidation._type) {
    const queryParams = new URLSearchParams();
    queryParams.append("offset", `${page}`)
    queryParams.append("filters", JSON.stringify(filters));
    return queryParams;
}

export function fetchNewsArticlesPage(page: number, filters: typeof ArticleFilterValidation._type) {
    const queryParams = buildFilters(page, filters);

    return fetchApiGet<{
        data: NewsArticle[]
        total: number;
        totalPages: number;
        pageSize: number;
        currentPage: number;
    }>(`news/articles`, queryParams);
}

export function useListNewsArticles({ page, filters, queryFn }: {
    page: number,
    filters: typeof ArticleFilterValidation._type,
    queryFn: any,
}) {
    const queryParams = buildFilters(page, filters);
    return useQuery({
        queryKey: ["feeds", "newsArticles", queryParams.toString()],
        queryFn,
        retry: 1,
        cacheTime: 0,
        keepPreviousData: true,
    });
}