import { useState } from "react";
import { FEATURE_GROUPS, PossibleRole, doesUserHavePackages, doesUserPackageHavePermissionTypes, getUserPackages, getUserRole } from "lhc_permissions";
import { GenerateLoadingOrError } from "../../lib/utils";
import { useListUsers, DbUserWithPackages as User, useUpdateUserPermissions } from "../../lib/api/users";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { DataTable } from "../../components/ui/dataTable";
import { PrimitiveManagedTable } from "@/lib/form/layout";
import { InputFormType, InputRequiredStatus, InputType } from "@/lib/form";
import { useToast } from "@/components/ui/use-toast";

interface Feature {
  name: string;
  requiredPermissions?: string[];
  requiredPackages?: string[];
}

const UserSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    name: { label: "Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    email: { label: "Email", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },

    role: { label: "Role", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const FeatureSchema = {
    id: { label: "Feature ID", hidden: true, input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    feature_group_name: { label: "Feature Group", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    feature_name: { label: "Feature", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

export function ManagePermissionsPage() {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<string>("");
  const usersRes = useListUsers();

  const loadingOrErrorUi = GenerateLoadingOrError([usersRes]);
  if (loadingOrErrorUi) return loadingOrErrorUi;

  const users = usersRes?.data?.data || [];

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl mb-6">
        Manage Permissions
      </h1>

      <PrimitiveManagedTable
        schema={UserSchema}
        values={users || []}
        onRowClick={(user: any) => setSelectedUser(user)}
      />

      <UserPermissionsDialog
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
    </section>
  );
}

function UserPermissionsDialog({ user, onClose }: { user: User | null; onClose: () => void }) {
  const { toast } = useToast();
  const [selectedFeature, setSelectedFeature] = useState<any | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateUserPermissions = useUpdateUserPermissions();

  if (!user) return null;

  const featureData = FEATURE_GROUPS.reduce((acc, feature_group) => {
    feature_group.features.forEach((feature) => {
        if (hasAccessToFeature(feature, { trusted_metadata: { packages: user.packages, role: user.role } })) {
            acc.accessible_features.push({
                id: `${feature_group.name}|${feature.name}`,
                feature_group_name: feature_group.name,
                feature_name: feature.name,
                feature
            });
        } else {
            acc.non_accessible_features.push({
                id: `${feature_group.name}|${feature.name}`,
                feature_group_name: feature_group.name,
                feature_name: feature.name,
                feature
            });
        }
    });

    return acc;
  }, { accessible_features: [] as any[], non_accessible_features: [] as any[] });

  featureData.accessible_features.sort((a, b) => {
    return a.feature_group_name.localeCompare(b.feature_group_name) || a.feature_name.localeCompare(b.feature_name);
  });

  return (
    <Dialog open={!!user} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>{user.name}'s Permissions</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div>
            <h3 className="font-semibold mb-2">Granted Features</h3>
            <PrimitiveManagedTable
                schema={FeatureSchema}
                values={featureData.accessible_features || []}
            />
          </div>

          <div className="flex gap-2 items-end">
            <div className="flex-1">
              <label className="text-sm font-medium mb-1 block">
                Add Feature
              </label>
              <Select
                value={selectedFeature?.feature_id}
                onValueChange={setSelectedFeature}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select feature to add..." />
                </SelectTrigger>
                <SelectContent>
                  {featureData.non_accessible_features.map((feature: any) => (
                    <SelectItem key={`NonAccessible:${feature.id}`} value={feature.id}>
                        <span className="flex flex-row cursor-pointer">
                            <span className="text-xs font-semibold">
                                {feature.feature_group_name}
                            </span>
                            <span className="text-xs mx-2">:</span>
                            <span className="text-xs">
                                {feature.feature_name}
                            </span>
                        </span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button
              disabled={!selectedFeature || isSubmitting}
              onClick={async () => {
                if (isSubmitting) return;
                setIsSubmitting(true);
                try {
                    const requested_feature_group = selectedFeature.split("|")[0];
                    const requested_feature_type = selectedFeature.split("|")[1];
                    const requested_feature = FEATURE_GROUPS
                        .find((feature_group) => feature_group.name === requested_feature_group)?.features
                        .find((feature) => feature.name === requested_feature_type);
                    const requested_packages = (requested_feature?.requiredPackages || []).map((a_package: string) => [a_package, requested_feature?.requiredPermissions?.join("")].join(":"));
                    const new_permissions = Array.from(new Set([...user.packages, ...requested_packages]));

                    const result = await updateUserPermissions({
                        user_id: user.id,
                        permissions: new_permissions
                    });
                    if (!result.success) {
                        toast({
                            title: "Failed to add feature",
                            description: result.message || "An unknown error occurred",
                        });
                    } else {
                        toast({
                            title: "Added",
                            description: "",
                        });
                    }

                } catch(error: any) {
                    toast({
                        title: "Failed to add feature",
                        description: error?.message || "An unknown error occurred",
                    });
                } finally {
                    setIsSubmitting(false);
                }
              }}
            >
              Add Feature
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function hasAccessToFeature(
  feature: Feature,
  member: any
): boolean {
  if (!feature.requiredPackages?.length) return true;

  if (!feature.requiredPermissions?.length) {
    return doesUserHavePackages({ member, requiredPackages: feature.requiredPackages as any[] });
  }

  return doesUserHavePackages({ member, requiredPackages: feature.requiredPackages as any[] }) &&
     feature.requiredPackages.every((requiredPackage) =>
        doesUserPackageHavePermissionTypes({
            role: getUserRole(member),
            packages: getUserPackages(member),
            requiredPackage: requiredPackage as any,
            requiredPermissions: feature.requiredPermissions as any[]
        })
     );
}

export default ManagePermissionsPage;
