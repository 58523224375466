"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURE_GROUPS = exports.PossiblePackage = exports.PossibleRole = exports.PermissionType = void 0;
exports.doesUserPackageHavePermissionTypes = doesUserPackageHavePermissionTypes;
exports.doesUserHavePackages = doesUserHavePackages;
exports.getUserPackages = getUserPackages;
exports.getUserRole = getUserRole;
exports.getFeaturesByPackages = getFeaturesByPackages;
exports.getRequiredPackagesForFeature = getRequiredPackagesForFeature;
var PermissionType;
(function (PermissionType) {
    PermissionType["Read"] = "r";
    PermissionType["Write"] = "w";
    PermissionType["Update"] = "u";
    PermissionType["Delete"] = "d";
    PermissionType["Manager"] = "m";
})(PermissionType || (exports.PermissionType = PermissionType = {}));
var PossibleRole;
(function (PossibleRole) {
    PossibleRole["Admin"] = "admin";
    PossibleRole["User"] = "user";
    PossibleRole["Developer"] = "developer";
})(PossibleRole || (exports.PossibleRole = PossibleRole = {}));
var PossiblePackage;
(function (PossiblePackage) {
    PossiblePackage["NewsArticle"] = "news_article";
    PossiblePackage["AtsRead"] = "ats_read";
    PossiblePackage["AtsAction"] = "ats_action";
    PossiblePackage["AtsBacktest"] = "ats_backtest";
    PossiblePackage["Tickets"] = "tickets";
    PossiblePackage["HedgeBeacon"] = "hedge_beacon";
    PossiblePackage["HedgeBeaconMarketThoughts"] = "hedge_beacon_market_thoughts";
    PossiblePackage["Communications"] = "communications";
    PossiblePackage["Developer"] = "developer";
    PossiblePackage["TransactionalReports"] = "transactional_reports";
    PossiblePackage["ListTraders"] = "list_traders";
    PossiblePackage["SignatureDocuments"] = "signature_documents";
    PossiblePackage["Freight"] = "freight";
    PossiblePackage["ManagerCustomer"] = "manage_customers";
    PossiblePackage["OTC"] = "otc";
    PossiblePackage["OTCAdmin"] = "otc_admin";
    PossiblePackage["GeneralContract"] = "general_contract";
    PossiblePackage["General"] = "general";
    PossiblePackage["ClientNotification"] = "client_notification";
    PossiblePackage["Employee"] = "employee";
    PossiblePackage["Destination"] = "destination";
    PossiblePackage["News"] = "news";
    PossiblePackage["ManagePermission"] = "manage_permissions";
})(PossiblePackage || (exports.PossiblePackage = PossiblePackage = {}));
exports.FEATURE_GROUPS = [
    {
        name: "Trading",
        description: "ATS and trading related features",
        features: [
            {
                name: "ATS View",
                description: "View ATS trading information",
                requiredPackages: [PossiblePackage.AtsRead],
            },
            {
                name: "ATS Control",
                description: "Control ATS trading actions",
                requiredPackages: [PossiblePackage.AtsAction],
            },
            {
                name: "ATS Backtest",
                description: "Run ATS backtests",
                requiredPackages: [PossiblePackage.AtsBacktest],
            },
        ]
    },
    {
        name: "OTC",
        description: "OTC",
        features: [
            {
                name: "OTC Basic",
                description: "Basic OTC operations",
                requiredPackages: [PossiblePackage.OTC, PossiblePackage.ListTraders, PossiblePackage.ManagerCustomer],
            },
            {
                name: "OTC Administration",
                description: "Advanced OTC operations",
                requiredPackages: [PossiblePackage.OTC, PossiblePackage.OTCAdmin],
            }
        ]
    },
    {
        name: "General Contracts",
        description: "",
        features: [
            {
                name: "General Contracts",
                description: "General & Speech Contracts",
                requiredPackages: [PossiblePackage.GeneralContract, PossiblePackage.General, PossiblePackage.ManagerCustomer, PossiblePackage.ListTraders],
            },
            {
                name: "Manager General Contracts",
                description: "See everyones General Contracts",
                requiredPackages: [PossiblePackage.GeneralContract],
                requiredPermissions: [PermissionType.Manager],
            }
        ]
    },
    {
        name: "Hedge Beacon",
        description: "Hedge Beacon",
        features: [
            {
                name: "Hedge Beacon Analyst Recommendations",
                description: "",
                requiredPackages: [PossiblePackage.HedgeBeacon],
            },
            {
                name: "Hedge Beacon Market Thoughts",
                description: "",
                requiredPackages: [PossiblePackage.HedgeBeaconMarketThoughts],
            }
        ]
    },
    {
        name: "Tickets",
        description: "Tickets",
        features: [
            {
                name: "Tickets",
                description: "Weight and Trading Tickets",
                requiredPackages: [PossiblePackage.Tickets],
            }
        ]
    },
    {
        name: "Mobile App",
        description: "",
        features: [
            {
                name: "Client Notification",
                description: "Client Notification",
                requiredPackages: [PossiblePackage.ClientNotification],
            },
            {
                name: "Staff Contacts",
                description: "",
                requiredPackages: [PossiblePackage.Employee],
            }
        ]
    },
    {
        name: "Signature Documents",
        description: "",
        features: [
            {
                name: "Signature Documents",
                description: "Signature Documents",
                requiredPackages: [PossiblePackage.SignatureDocuments],
            }
        ]
    },
    {
        name: "Customers",
        description: "",
        features: [
            {
                name: "Manage Customers",
                description: "",
                requiredPackages: [PossiblePackage.ManagerCustomer],
            }
        ]
    },
    {
        name: "Traders",
        description: "",
        features: [
            {
                name: "Manage Traders",
                description: "",
                requiredPackages: [PossiblePackage.ManagerCustomer, PossiblePackage.ListTraders],
            }
        ]
    },
    {
        name: "Reports",
        description: "",
        features: [
            {
                name: "Reports",
                description: "",
                requiredPackages: [PossiblePackage.TransactionalReports, PossiblePackage.ListTraders, PossiblePackage.ManagerCustomer],
            }
        ]
    },
    {
        name: "Destination",
        description: "",
        features: [
            {
                name: "Destination",
                description: "",
                requiredPackages: [PossiblePackage.Destination, PossiblePackage.ManagerCustomer],
            }
        ]
    },
    {
        name: "Common",
        description: "",
        features: [
            {
                name: "Locations",
                description: "",
                requiredPackages: [PossiblePackage.General],
            },
            {
                name: "Market Zones",
                description: "",
                requiredPackages: [PossiblePackage.General],
            },
            {
                name: "Staff Contacts",
                description: "",
                requiredPackages: [PossiblePackage.Employee],
            }
        ]
    },
    {
        name: "News Articles",
        description: "",
        features: [
            {
                name: "News",
                description: "",
                requiredPackages: [PossiblePackage.News],
            }
        ]
    },
    // {
    //     name: "Developer",
    //     description: "",
    //     features: [
    //         {
    //             name: "Developer",
    //             description: "",
    //             requiredPackages: [PossiblePackage.Developer],
    //         }
    //     ]
    // }
];
// NOTE: Currently this is expected to be used in route handles after package auth has already been established.
// To check if the user has only specific permissions
// if no colon permissions its assumed you have user permissions for that package.
// If colon, then it assumes you only have those permissions. so hi:r is only Read for that package
// The manager option dictates managing your own content and viewing others (sometimes with audit info)
// so if you have the base package no colon, but its requesting Manager permission and you are not an admin or developer
// it assumes you dont have the right.
function doesUserPackageHavePermissionTypes({ role, packages, requiredPackage, requiredPermissions }) {
    if (role === PossibleRole.Admin)
        return true;
    if (role === PossibleRole.Developer)
        return true;
    if (!requiredPermissions.length)
        return true;
    const splitPackages = packages.map((aPackage) => aPackage.split(":")[0]);
    const foundPackageIndexes = splitPackages.map((aPackage, index) => { if (aPackage === requiredPackage) {
        return index;
    }
    else {
        return undefined;
    } }).filter((item) => item != undefined);
    if (!foundPackageIndexes.length)
        return false;
    const foundPackage = packages.find((aPackage, index) => foundPackageIndexes.includes(index) && aPackage.includes(":")) || packages[foundPackageIndexes[0]];
    const hasPermissionList = foundPackage.includes(":");
    if (requiredPermissions.includes(PermissionType.Manager) && !hasPermissionList)
        return false;
    if (!hasPermissionList)
        return true;
    const permissionSection = foundPackage.split(":")[1];
    for (const permission of requiredPermissions) {
        if (!permissionSection.includes(permission)) {
            return false;
        }
    }
    return true;
}
function doesUserHavePackages({ requiredPackages, member }) {
    if (!requiredPackages)
        return true;
    if (!requiredPackages.length)
        return true;
    const basePackages = getUserPackages(member);
    const role = getUserRole(member);
    const packages = basePackages.map(aPackage => aPackage.split(":")[0]);
    // NOTE: Admin roles does not superseed developer requirement
    // Dont let Admins do actions that they dont understand the full context of.
    if (!!requiredPackages.includes(PossiblePackage.Developer) && !packages.includes(PossiblePackage.Developer)) {
        return false;
    }
    if (role === PossibleRole.Admin)
        return true;
    if (!packages.length)
        return false;
    for (const requiredPackage of requiredPackages) {
        if (!packages.includes(requiredPackage))
            return false;
    }
    return true;
}
function getUserPackages(member) {
    return (member === null || member === void 0 ? void 0 : member.trusted_metadata["packages"]) || [];
}
function getUserRole(member) {
    return (member === null || member === void 0 ? void 0 : member.trusted_metadata["role"]) || PossibleRole.User;
}
function getFeaturesByPackages(packages) {
    return exports.FEATURE_GROUPS.flatMap(group => group.features.filter(feature => feature.requiredPackages.every(pkg => packages.includes(pkg))));
}
function getRequiredPackagesForFeature(featureName) {
    const feature = exports.FEATURE_GROUPS.flatMap(g => g.features)
        .find(f => f.name === featureName);
    return (feature === null || feature === void 0 ? void 0 : feature.requiredPackages) || [];
}
